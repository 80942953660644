<template>
  <el-dialog
    append-to-body
    title="生成发票"
    :visible.sync="visible"
    width="500px"
    :show-close="true"
  >
    <div class="cont">
      <div class="cont-tips">
        <p>1.发票数据会生成到【开票管理-&gt;发票开具-&gt;待开发票】；</p>
        <p>2.根据业务要求，生成的发票不能进行发票拆分、合并操作。</p>
        <br />
        <span v-if="trades.length == 1"
          >确定要将订单【{{ sheetCode }}】的数据 “生成发票” 吗？</span
        >
        <span v-else
          >确定要将所选的【{{ trades.length }}】条订单的数据 “生成发票”
          吗？</span
        >
      </div>
      <div slot="footer">
        <el-button
          @click="handleCancelGenerate"
          :disabled="loading"
          :loading="loading"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="handleConfirmGenerate"
          :disabled="loading"
          :loading="loading"
          >确 定</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { reqGenerateInvoice } from "@/service/coo/trade.js";

export default {
  name: "SettlementGenerateInvoice",
  data() {
    return {
      trades: [],
      loading: false,
      visible: false,
    };
  },
  computed: {
    sheetCode() {
      return this.trades.length == 1 ? this.trades[0].sheetCode : "";
    },
  },
  methods: {
    // 生成发票弹窗
    showDialog(trades) {
      if (trades.length < 1) {
        this.toast("生成发票失败，没有指定订单数据！", "warning");
        return;
      }

      if (
        trades.filter((trade) => {
          return (
            trade.sheetStage != "BI" ||
            ["DDCL", "CLSB", "BFKP"].indexOf(trade.sheetStatus) == -1
          );
        }).length > 0
      ) {
        this.toast("生成发票失败，包含已开票的订单！", "warning");
        return;
      }

      this.trades = trades;
      this.visible = true;
    },
    // 取消生成发票
    handleCancelGenerate() {
      this.visible = false;
    },
    // 确认生成发票
    async handleConfirmGenerate() {
      this.loading = true;
      const { success, message } = await reqGenerateInvoice({
        sheetIds: this.trades.map((item) => item.id),
      });

      this.loading = false;
      if (success) {
        this.toast(message, "success");
        this.$emit("success");
        this.visible = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.cont {
  font-size: 14px;
  padding: 30px 40px 30px 40px;

  .cont-tips {
    text-align: left;
    padding-bottom: 30px;

    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #f5222d;
      line-height: 20px;
    }
  }
}
</style>