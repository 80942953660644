<template>
  <el-dialog
    append-to-body
    title="回写订单"
    :visible.sync="visible"
    width="500px"
    :show-close="false"
  >
    <div class="cont">
      <div v-if="trades.length == 1" class="cont-body">
        <label-span label="结算单号：" :span="trade.sheetCode" />
        <label-span label="供方名称：" :span="trade.sellerMainName" />
        <label-span label="需方名称：" :span="trade.buyerMainName" />
        <label-span label="价税合计：" :span="fmtAmount(trade.sumAmount)" />
        <br />
        <span>确定要将订单数据 “回写系统” 吗？</span>
      </div>
      <div v-else class="cont-body">
        <label-span label="订单数量：" :span="trades.length" />
        <br />
        <span>确定要将订单数据 “回写系统” 吗？</span>
      </div>
    </div>
    <div class="dialog-footer" slot="footer">
      <el-button type="default" :loading="loading" @click="handleCancelReturn"
        >取消</el-button
      >
      <el-button type="primary" :loading="loading" @click="handleConfirmReturn"
        >确认</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { fmtMoney } from "@/assets/js/format-util.js";
import { reqReturnSettlement } from "@/service/coo/trade.js";

export default {
  name: "SettlementReturnTrade",
  data() {
    return {
      loading: false,
      visible: false,
      trades: [],
    };
  },
  computed: {
    trade() {
      return this.trades.length == 1 ? this.trades[0] : {};
    },
  },
  methods: {
    // 订单回写弹窗
    showDialog(trades) {
      if (trades.length < 1) {
        this.toast("回写订单失败，没有指定订单数据！", "warning");
        return;
      }

      if (
        trades.filter((trade) => {
          return ["CS", "WJ"].indexOf(trade.sheetStage) == -1;
        }).length > 0
      ) {
        this.toast("回写订单失败，含非终阶段的订单！", "warning");
        return;
      }

      this.trades = trades;
      this.visible = true;
    },
    // 确认回写订单
    async handleConfirmReturn() {
      this.loading = true;
      const { success, message } = await reqReturnSettlement({
        sheetIds: this.trades.map((item) => item.id),
      });

      this.loading = false;
      if (success) {
        this.visible = false;
        this.toast(message, "success");
        this.$emit("success");
      }
    },
    // 取消回写订单
    handleCancelReturn() {
      this.visible = false;
    },
    // 格式订单金额
    fmtAmount(value) {
      return fmtMoney(value);
    },
  },
};
</script>
<style lang="scss" scoped>
.cont {
  padding: 30px 40px 30px 40px;
  text-align: left;

  .cont-body {
    padding-bottom: 12px;

    .label-span {
      margin-bottom: 12px;
    }

    span {
      font-size: 14px;
      color: #f5222d;
    }
  }
}
</style>