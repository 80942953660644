import {get, post, download} from "./index"

/**
 * 获取供用商列表
 * @param data
 * @returns {Promise<unknown>}
 */
export const getList = data => {
    return post("/cooperate-seller/getList", data).then(res => res);
}
/**
 *
 * @param data
 * @returns {Promise<*>}
 */
export const saveSellerDetail = data => {
    return post("/cooperate-seller/saveSellerDetail", data).then(res => res);
}

/**
 * 发起协同
 * @param data
 * @returns {Promise<unknown>}
 */
export const saveBatchBinding = data => {
    return post("/binding-relation/insert", data).then(res => res);
}

/**
 * 新增供应商
 * @param data
 * @returns {Promise<unknown>}
 */
export const insertSupplier = data => {
    return post("/cooperate-seller/insertSupplier", data).then(res => res);
}

/**
 * 销售方批量维护模版下载
 * @param data
 * @returns {Promise<unknown>}
 */
export const downloadCooperateSellerTemplate = data => {
    return download("/cooperate-seller/downloadCooperateSellerTemplate", data).then(res => res);
}

/**
 * 销售方批量维护信息上传
 * @param data
 * @returns {Promise<unknown>}
 */
export const batchImportCooperateSeller = data => {
    return post("/cooperate-seller/batchImportCooperateSeller", data).then(res => res);
}