<template>
  <el-dialog
    append-to-body
    title="删除订单"
    :visible.sync="visible"
    width="500px"
    :show-close="true"
  >
    <div class="cont">
      <div class="cont-body">
        <label-span label="结算单号：" :span="settlement.sheetCode" />
        <label-span label="供方名称：" :span="settlement.sellerMainName" />
        <label-span label="需方名称：" :span="settlement.buyerMainName" />
        <label-span
          label="价税合计："
          :span="fmtAmount(settlement.sumAmount)"
        />
      </div>
      <div class="cont-tips">
        <span>注：作废会同步调用业务系统，请谨慎操作！</span>
      </div>
    </div>
    <div class="dialog-footer" slot="footer">
      <el-button type="default" :loading="loading" @click="handleCancelDelete"
        >取消</el-button
      >
      <el-button type="primary" :loading="loading" @click="handleConfirmDelete"
        >确认</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { fmtMoney } from "@/assets/js/format-util.js";
import { reqDeleteSettlement } from "@/service/coo/trade.js";

export default {
  name: "SettlementDeleteTrade",
  data() {
    return {
      settlement: {},
      loading: false,
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // 删除订单弹窗
    showDialog(trade) {
      if (!trade || !trade.id || trade.id < 0) {
        this.toast("删除订单失败，没有指定订单主键！", "warning");
        return;
      }
      this.settlement = trade;
      this.visible = true;
    },
    // 确认删除订单
    async handleConfirmDelete() {
      this.loading = true;

      const { success } = await reqDeleteSettlement(this.settlement.id);
      if (success) {
        this.toast("删除订单成功", "success");
        this.visible = false;
      }

      this.loading = false;
    },
    // 取消删除订单
    handleCancelDelete() {
      this.visible = false;
    },
    // 格式订单金额
    fmtAmount(value) {
      return fmtMoney(value);
    },
  },
};
</script>
<style lang="scss" scoped>
.cont {
  padding: 30px 40px 30px 40px;
  text-align: left;

  .cont-body {
    padding-bottom: 12px;

    .label-span {
      margin-bottom: 12px;
    }
  }

  .cont-tips {
    padding-top: 24px;

    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #f5222d;
      line-height: 20px;
    }
  }
}
</style>